@tailwind base;
@tailwind components;
@tailwind utilities;
/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

/* lazy image */
@import 'react-lazy-load-image-component/src/effects/blur.css';

/* map */
@import 'mapbox-gl/dist/mapbox-gl.css';

/* editor */
@import 'react-quill/dist/quill.snow.css';

/* carousel */
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

/* lightbox */
@import 'yet-another-react-lightbox/styles.css';
@import 'yet-another-react-lightbox/plugins/captions.css';
@import 'yet-another-react-lightbox/plugins/thumbnails.css';